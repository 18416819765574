<template>
    <div class="infoCard">
		<div class="cardIcon">
			<img src="@/assets/page1/Group1321314659.png" class="iconImg" alt="">
		</div>
		<div class="infoBox">
			<div class="itemName">{{itemName}}</div>
			<div class="underbar"></div>
			<div class="infoValueBox">
				<!--<span class="infoValue">{{content2}}</span>-->
				<active-number :number="itemValue" class="infoValue"></active-number>
			</div>
		</div>
	</div>
</template>

<style lang="less" scoped>
.infoCard {
	height: 100%;
	display: flex;
	align-items: center;

	.cardIcon {
		width: 10%;
		height: 55.79%;
		margin-left: 6.5%;

		.iconImg {
			width: 100%;
			height: 100%;
		}
	}

	.infoBox {
		height: 100%;
		width: 100%;
		margin-left: 1.63%;

		.itemName {
			display: flex;
			align-items: center;
			height: 39.29%;
			line-height: 1.3vh;
			font-size: 1.3vh;
			color: rgba(255,255,255,0.8);
			font-family: 'pingfangSc',sans-serif;
			margin-top: 5.36%;

		}
		.underbar {
			width: 26.02%;
			height: 5.36%;
			background: #ffd03b;
			margin-top: -3%;
		}

		.infoValueBox {
			height: 41.07%;
			margin-top: 0.37vh;

			.infoValue {
				font-size: 1.85vh;
				line-height: 2.13vh;
				font-family: 'titleFont', sans-serif;
				background: linear-gradient(180deg, #fff 42%, #ffd03b 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				letter-spacing: 1px;
				font-weight: 400;
			}
		}

	}

}
</style>

<script>
const activeNumber = () => import('@/views/components/activeNumber.vue');

export default {
	props:['itemName','itemValue'],
	components:{
		activeNumber
	},
	data(){
		return {
		}
	},
	mounted(){
		return false;
	}
}
</script>

